import V from 'voUtils/V.js';
import QuoteTools from 'voUtils/QuoteTools.js';
import {
	getVoucher
}
from 'voUtils/VoucherStore.js';

import {
	doReq
}
from 'voUtils/RestEx.js';
import {
	fireQuote, fireUnitAction, fireCheckout
}
from 'voUtils/TagEx.js';
import {
	find, filter, isEmpty, oneIs
}
from 'voUtils/tools.js';
import DateEx from 'voUtils/DateEx.js';

export default {

	data: {
		bookingData: {
			adults: 2,
			children: 0,
			childrenAges: undefined,
			babys: 0,
			petsCount: 0,
			from: undefined,
			till: undefined
		},
		action: undefined,
		step: undefined, //'booking',
		priceIsUpdated: false,
		showPriceDetails: false,
		quote: {},
		error: undefined,
		preview: undefined,
		summary: undefined,
		selectedImageIndex: undefined,
		selectedTravelInsurance: 0,
		travelInsurancePlans: undefined,
		travelInsuranceTarget: 'test',
		bookingMode: undefined
	},
	created: function () {
		this.calListeners = [];
		this.QuoteTools = QuoteTools;

		var params = new URLSearchParams(new URL(window.location).search);
		for (let [k, v] of params) {
			if (oneIs(k, 'ref_adults', 'ref_children', 'ref_babys', 'ref_from', 'ref_till')) {
				this.bookingData[k.substring(4)] = v;
			}
		}

		var hash = window.location.hash;
		if (hash) {
			hash = hash.substring(1);

			let url = new URL(hash, 'https://www.v-office.com');
			let params = new URLSearchParams(url.search);

			for (let p of params) {
				if ((p[0] == 'children' || p[0] == 'babys') && !this.areChildrenWelcome()) {
					//ignore not welcome
				} else if (p[0] == 'petsCount' && !this.arePetsWelcome()) {
					//ignore not welcome
				} else {
					this.bookingData[p[0]] = p[1];
				}
			}
		}


		this.updatePrices();
	},
	computed: {
		showBookingButtons: function () {
			return this.quote && this.quote.mandatory && !this.priceIsUpdated && isEmpty(this.quote.errors) && !this.action;
		},
		canDoNext: function () {
			return this.quote && this.quote.mandatory && !this.priceIsUpdated && isEmpty(this.quote.errors) && this.action;
		},
		periodLabel: function () {
			let label = '';
			if (this.bookingData.from) {
				label = this.voFormatDate(this.bookingData.from, 'withDay');
			}
			if (this.bookingData.till) {
				label += ' - ' + this.voFormatDate(this.bookingData.till, 'withDay');
			}

			return label;
		},
		guestLabel: function () {
			var sd = this.bookingData;
			var amount = ((sd.adults || 0) - 0) + ((sd.children || 0) - 0) + ((sd.babys || 0) - 0);
			var label = amount + ' ' + this.voMsgPl('guest.pl', amount);
			if (sd.petsCount) {
				label += ', ' + sd.petsCount + ' ' + this.voMsgPl('pet.pl', sd.petsCount);

			}
			return label;
		}


	},

	methods: {

		checkBrowserLanguage: function () {
			if (navigator.language && (navigator.language != 'de-DE' && navigator.language != 'de')) {
				return true;
			}
			return false;
		},
		checkVOFFICElang: function () {
			if (VOFFICE.lang === 'de') {
				return true;
			}
			return false;
		},
		selectTravelInsurance: function (plan) {
			this.selectedTravelInsurance = plan;
		},


		getBookingMode: function () {
			if (!this.bookingMode) {
				let el = document.querySelector("[data-unit-bookingmode]");
				if (el) {
					this.bookingMode = el.getAttribute('data-unit-bookingmode');
				}
			}
			return this.bookingMode;
		},


		getTravelInsurancePlans: function () {


			var data = this.bookingData;
			var canUseSamePlan = false;
			var prevSelectedPlan = this.selectedTravelInsurance;
			var bookingMode = this.getBookingMode();


			if (data.from && data.till && this.quote && this.quote.travelInsuranceIsSetup && bookingMode === "DIRECTLY") {


				var req = {
					fromdate: DateEx.interfaceFormat(data.from),
					tilldate: DateEx.interfaceFormat(data.till),
					adults: data.adults,
					children: data.children,
					babys: data.babys,
					unit: this.getUnitId(),
					totalBookingAmount: QuoteTools.getTotal(this.quote, 'all')
				};
				if (VOFFICE.travelInsurance && VOFFICE.travelInsurance.forceTravelInsuranceMode) {
					req.forceTravelInsuranceMode = VOFFICE.travelInsurance.forceTravelInsuranceMode;
				}
				console.log('***totalBookingAmount', req.totalBookingAmount);
				doReq('getTravelInsurancePlans', req)
					.then((res) => {
						this.travelInsuranceTarget = res.target ? res.target : 'test';
						var ix;


						console.log('getTravelInsurancePlans', res, this.travelInsuranceTarget);
						if (this.travelInsurancePlans) {
							canUseSamePlan = true;


							for (ix in res.plans) {
								//console.log(this.travelInsurancePlans[ix].amount);
								if (!this.travelInsurancePlans[ix]) {
									canUseSamePlan = false;
								} else if (res.plans[ix].planid != this.travelInsurancePlans[ix].planid) {
									canUseSamePlan = false;
								} else if (res.plans[ix].planCost.amount != this.travelInsurancePlans[ix].planCost.amount) {
									canUseSamePlan = false;
								}
							}
						}
						if (!canUseSamePlan) {
							if (prevSelectedPlan) {
								this.selectedTravelInsurance = null;
							}
							this.travelInsurancePlans = res.plans;


							if (prevSelectedPlan) {
								for (ix in travelInsurancePlans) {
									if (this.travelInsurancePlans[ix].name == prevSelectedPlan.name) {
										this.selectedTravelInsurance = this.travelInsurancePlans[ix];
									}
								}
							}
						}
					});
			}
		},

		addLine: function (quote, sl) {
			QuoteTools.addLine(quote, sl);
			this.$forceUpdate();
			this.getTravelInsurancePlans();
		},
		removeLine: function (quote, sl) {
			QuoteTools.removeLine(quote, sl);
			this.$forceUpdate();
			this.getTravelInsurancePlans();
		},
		clearPrices: function () {
			this.quote = {};
			this.summary = undefined;
			this.preview = undefined;
			this.previewQuote = undefined;
		},
		updatePrices: function () {

			var data = this.bookingData;
			this.error = undefined;
			if (data.from && data.till) {

				this.priceIsUpdated = true;
				this.quote = {};

				var req = {
					fromdate: DateEx.interfaceFormat(data.from),
					tilldate: DateEx.interfaceFormat(data.till),
					adults: data.adults,
					children: data.children,
					babys: data.babys,
					petsCount: data.petsCount,
					unit: this.getUnitId(),
					includeServiceLimits: true,
				};

				if (VOFFICE.quoteOptions) {
					Object.assign(req, VOFFICE.quoteOptions);
				}

				var voucher = getVoucher();
				if (voucher) {
					req.voucherCode = voucher.code;
				}

				if (VOFFICE.abtest) {
					req.abtest = VOFFICE.abtest;
				}

				if (data.childrenAges) {
					req.childrenAges = data.childrenAges;
				}

				if (VOFFICE.travelInsurance) {
					if (VOFFICE.travelInsurance.checkTravelInsuranceIsSetup) {
						req.checkTravelInsuranceIsSetup = true;


						if (VOFFICE.travelInsurance.forceTravelInsuranceMode) {
							req.forceTravelInsuranceMode = VOFFICE.travelInsurance.forceTravelInsuranceMode;
						}
					}
				}

				doReq('quotePrices', req)
					.then((res) => {
						this.quote = res.quote;
						this.priceIsUpdated = false;
						this.quote.optional.forEach(sl => {
							sl.optional = true;
							sl.amount = null;
							this.QuoteTools.updateTotal(sl);
						});
						this.quote.onsiteOptional.forEach(sl => {
							sl.onsiteOptional = true;
							sl.amount = null;
							this.QuoteTools.updateTotal(sl);
						});

						if (res.quote.mandatory) {
							this.summary = find(res.quote.mandatory, function (sl) {
								return sl.service.type === 'SUMMARY';
							});

							this.preview = filter(res.quote.mandatory, function (sl) {
								return !VOFFICE.hideTypeOnPricePreview || VOFFICE.hideTypeOnPricePreview.indexOf(sl.service.type) == -1;
							});

							if (!this.preview || this.preview.length < 2) {
								this.preview = undefined;
								this.previewQuote = undefined;
							} else {
								this.previewQuote = {
									mandatory: this.preview,
									hasDiscounts: this.quote.hasDiscounts
								};
							}

						}

						this.getTravelInsurancePlans();

						fireQuote(this.getCart());

					})
					.catch(e => {
						this.priceIsUpdated = false;
						this.error = e;
					});


			}

		},
		getCart: function () {
			// workarround 
			this.quote.selectedTravelInsurance = this.selectedTravelInsurance;
			this.quote.travelInsuranceTarget = this.travelInsuranceTarget;

			return {
				action: this.action,
				quote: this.quote,
				booking: Object.assign({}, this.bookingData),
				unitId: this.getUnitId()
			};
		},

		goAction: function (action) {
			this.action = action;
			this.showPriceDetails = true;
			fireUnitAction(this.getCart());
		},

		putInCart: function () {
			// @todo pruefen, ob eine Versicherung gewählt wurde
			if (this.quote.travelInsuranceIsSetup) {
				if (this.selectedTravelInsurance == null) {
					console.log("Versicherung wählen!");
				}
			}
			this.step = 'booking';
			fireCheckout(this.getCart());
			window.scrollTo(0, 0);

		},
		cancelBooking: function () {
			this.step = undefined;
		},
		cancel: function () {
			this.action = undefined;
			this.showPriceDetails = false;

		},


		getUnitId: function () {
			if (!this.unitId) {
				let el = document.querySelector("[data-unit-id]");
				if (el) {
					this.unitId = parseInt(el.getAttribute('data-unit-id'), 10);
				}
			}
			return this.unitId;
		},
		areChildrenWelcome: function () {
			if (this.childrenWelcome == undefined) {
				let el = document.querySelector("[data-children-welcome]");
				if (el) {
					this.childrenWelcome = el.getAttribute('data-children-welcome') == '1';
				} else {
					this.childrenWelcome = false;
				}
			}
			return this.childrenWelcome;
		},
		arePetsWelcome: function () {
			if (this.petsWelcome == undefined) {
				let el = document.querySelector("[data-pets-welcome]");
				if (el) {
					this.petsWelcome = el.getAttribute('data-pets-welcome') == '1';
				} else {
					this.petsWelcome = false;
				}
			}
			return this.petsWelcome;
		},
		getCal: function (done) {
			if (this.cal) {
				done(this.cal);
			} else {
				this.calListeners.push(done);

				if (this.calListeners.length === 1) {

					var req = {
						unit: this.getUnitId()
					};

					doReq('getCal', req)
						.then((res) => {
							this.cal = res.cal;

							for (let l of this.calListeners) {
								l(res.cal);
							}
							this.calListeners.length = 0;

						});
				}
			}


		}
	},
	watch: {
		'bookingData.from': function () {
			if (!this.bookingData.till) {
				this.clearPrices();
			}
			this.getTravelInsurancePlans();


		},
		'bookingData.till': function () {
			this.getTravelInsurancePlans();
		}
	}
};